import * as React from "react"

const SSRPage = ({ serverData }) => (
  <main>
    <h1>SSR Page</h1>

    { <div dangerouslySetInnerHTML={{ __html: serverData.data[0].description }} /> }
  </main>
)

export default SSRPage

export async function getServerData() {
  try {
    const res = await fetch(`https://stage5345.oscarjaenada.com/api/v1/bio`)

    if (!res.ok) {
      throw new Error(`Response failed`)
    }

    return {
      props: await res.json(),
    }
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {}
    }
  }
}
